<template>
  <Modal title="Причина отклонения" :show="show" @hide="() => $emit('hide')">
    <div style="min-width: 320px; min-height: 320px" class="wrap-popup__inner medium-popup__inner">
      <b-form-textarea
        style="min-height: 200px"
        v-model="reason"
        class="mx-20 my-20"
      ></b-form-textarea>
      <div class="bottom">
        <b-button
          class="mx-auto"
          @click="
            () => {
              $emit('input', id, reason);
              $emit('hide');
            }
          "
          variant="outline-default"
          >Готово</b-button
        >
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@main/components/reusable/modals/Modal.vue';

export default {
  components: {
    Modal,
  },
  data: () => ({
    reason: null,
  }),
  props: {
    id: {
      type: [String, Number],
    },
    show: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.bottom {
  margin-top: 15px;
  display: block;
}
</style>
