<template>
  <div>
    <div class="wrapper">
      <div class="filters__mobile">
        <div class="filters__mobile--wrap">
          <div class="moderation__search filters--moderation--search" style="margin-right: 0">
            <input
              type="text"
              v-model="params.search"
              placeholder="Поиск (дата или ник)"
              class="moderation__search--input"
            />
            <div class="moderation__search--icon">
              <svg @click="setModerationBloggers" class="moderation__search--pic">
                <use xlink:href="../../assets/img/icons/sprite.svg#search-solid"></use>
              </svg>
            </div>
          </div>
          <div class="moderation__filters--buttons">
            <button class="btn moderation__filters--btn btn--theme--yellow">Найти</button>
            <button class="btn moderation__filters--btn btn--theme--white">Сбросить</button>
          </div>
          <div class="filters__cross">
            <span class="filters__cross--line"></span>
            <span class="filters__cross--line"></span>
          </div>
        </div>
      </div>

      <div>
        <div class="container" style="position: relative">
          <div
            class="statistics--card__title statistics--card__title--set mod__title"
            style="margin-bottom: 20px"
          >
            Модерация заявок
          </div>
          <h5 class="">Поиск и фильтр</h5>
          <div class="moderation">
            <div class="d-flex flex-row justify-content-between flex-wrap">
              <div class="moderation__buttons mt-3 overflow-auto">
                <b-button-group>
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 1 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(1, 'moderation_status')"
                    >Свежие</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 3 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(3, 'moderation_status')"
                    >Отклоненные</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 4 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(4, 'moderation_status')"
                    >На доработке</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 2 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(2, 'moderation_status')"
                    >Одобренные</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 5 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(5, 'moderation_status')"
                    >Забаненные</b-button
                  >
                  <b-button
                    class="ws-nowrap"
                    :variant="
                      Number(params.moderation_status) === 6 ? 'primary' : 'outline-default'
                    "
                    @click="() => changeParam(6, 'moderation_status')"
                    >Измененные</b-button
                  >
                </b-button-group>
              </div>
              <b-input-group class="custom-search-field ml-llg-4 mt-3">
                <b-form-input
                  v-model.lazy="params.search"
                  type="text"
                  placeholder="Поиск (дата или ник)"
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="setModerationBloggers" variant="outline-default"
                    ><b-icon icon="search"
                  /></b-button>
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="mt-4">
              <h4 class="moderation__title--small">Статистика блогеров</h4>
              <div class="d-flex flex-wrap justify-content-between">
                <ModerationCard
                  v-for="blogger in bloggers"
                  :moderationSteps="moderationSteps"
                  :key="blogger.id"
                  :blogger="blogger"
                  @update="updateModerationBlogger"
                />
              </div>
            </div>
            <pagination
              v-if="pages > 1"
              :current="params.page"
              :total="pages"
              @page-change="changeParam($event, 'page')"
            ></pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ModerationCard from './ModerationCard.vue';
import Pagination from '../Pagination.vue';
import bloggers from '../../api/bloggers';

export default {
  data: () => ({
    params: {
      page_size: 10,
      moderation_status: 1,
      moderationSteps: [],
      page: 1,
      search: '',
    },
    bloggers: [],
    pages: 0,
  }),
  components: {
    ModerationCard,
    Pagination,
  },
  methods: {
    async updateModerationBlogger(id, data) {
      const result = await bloggers.moderation.update(id, data);
      console.log('UPDATED BLOGGER STATUS AND DATA');
      console.log(result);
      if (data.last_moderation_history_item) {
        console.log('ASSIGNING REFUSE REASONS');
        const item = await bloggers.moderation.moderation_history_items.update({
          ...data.last_moderation_history_item,
          id: result.last_moderation_history_item.id,
        });
        console.log(item);
        await bloggers.moderation.moderation_history_items.sendNotification(item.id);
      }
      console.log('FETCHING BLOGGER LIST');
      await this.setModerationBloggers();
    },
    async setModerationBloggers() {
      const response = await bloggers.moderation.list(this.params);
      this.pages = response.total_pages;
      this.bloggers = response.results;
    },
    async filter(status) {
      this.params.status = status;
      await this.setModerationBloggers();
    },
    async changeParam(value, param) {
      this.$set(this.params, param, value);
      await this.setModerationBloggers();
    },
  },
  async mounted() {
    const res = await bloggers.moderation.steps();
    this.moderationSteps = res.results;
    await this.setModerationBloggers();
  },
};
</script>

<style lang="scss" scoped>
.btn__wrap {
  display: flex;
}
.moderation__row:not(:last-child) {
  margin-bottom: 20px;
}
.moderation-wrap {
  border: 1px solid green !important;
}
::v-deep .multiselect__select {
  height: 33px !important;
  top: 0px !important;
}

::v-deep .multiselect__tag {
  margin-bottom: 0px !important;
}

::v-deep .multiselect__placeholder {
  margin-bottom: 0px !important;
}
::v-deep .multiselect__single {
  display: block ruby;
  margin: auto auto !important;
  line-height: 22px;
  white-space: nowrap !important;
  max-width: 150px;
  overflow-x: hidden !important;
  text-overflow: ellipsis !important;
}
::v-deep .multiselect__tags {
  padding: 5px 40px 1px 8px !important;
  border: 1px solid #d5dfe4 !important;
  min-height: 36px !important;
  max-width: 100% !important;
  width: 100% !important;
  min-width: 0px !important;
}
::v-deep .multiselect {
  border-radius: 5px !important;
  width: 100% !important;
  min-height: 36px !important;
  min-width: 0px !important;
}
</style>
